import gql from "graphql-tag"

export const commonMixin = {
    data: function(){
        return {
            user: null,
            currentVersionBuildTime: '{{POST_BUILD_VERSION_BUILD_TIME}}',
        }
    },
    methods: {
        logout(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                    logout {
                        user {
                            id login username fam name isAnonymous isStaff email agentId 
                            groups{name} pos{id}
                            agent{id name ownStore}
                        }
                    }
                }`,
            }).then(data => { // eslint-disable-line no-unused-vars
                this.user=data.data.logout.user;
            }).catch(error => {
                this.error(JSON.stringify(error.message));
            });
        },
        print(){
            window.print();
            return false;
        },
    }
};
