<template>
    <div class="checkbox-slider" :class="{'disabled': disabled}">
        <input type="checkbox" :id="id" v-model="checked" @change="onChange" :disabled="disabled">
        <label :for="id" class="slider"></label>
        <label :for="id" class="action"><slot></slot></label>
    </div>
</template>

<script>
    export default {
        name: 'checkboxSlider',
        props: {
            init: Boolean,
            disabled: Boolean,
        },
        mounted () {
            this.id = this._uid;
            if (typeof this.init !== 'undefined')
                this.checked=this.init;
        },
        data() {
            return {
                id: null,
                checked: true,
            };
        },
        methods: {
            onChange(){
                if (this.disabled!=true)
                    this.$emit('input', this.checked);
            },
            setChecked(val){
                this.checked=val;
            }
        },
    }

</script>

<style lang="scss">
    .checkbox-slider {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .slider {
            position: relative;
            margin-right: 10px;
            width: 36px;
            height: 20px;
            background: #ddd;
            transition: .4s;
            border-radius: 15px;
            cursor: pointer;
        }
        .slider::before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 4px;
            top: 4px;
            background: white;
            transition: .4s;
            border-radius: 50%;
            box-shadow: 0 0 1px rgba(0,0,0,0.15);
        }
        input {
            display: none;
        }

        input:checked + .slider {
            background: #999;
        }
        input:checked + .slider:before {
            transform: translateX(16px);
        }
        label.action {
            border-bottom: 1px dashed;
            cursor: pointer;
        }
    }
    .checkbox-slider.disabled{
        label.slider {
            background: #ddd;
        }
    }
</style>