import Kkt from '@/components/KKM/Kkm.js'

const KktMixin = {
    data: ()=>( {
        kkt: null,
        isKkmConnected: false,
        isShiftOpened: null,
        is24HoursEnded: null,
        isPaperPresent: null,
        isWaitingAfterPaperOut: null,
    }),
    beforeDestroy(){
        this.disconnectKkt()
    },
    methods: {
        kktCreating(){
            setTimeout(()=>{this.connectKKT()}, 400);
            navigator.serial.addEventListener("connect", (event) => {
                if (!this.kkt || !this.kkt.isConnected())
                    this.connectKKT()
            });
            navigator.serial.addEventListener("disconnect", (event) => {
                if (this.kkt && this.kkt.posImpl && this.kkt.posImpl.serial && this.kkt.posImpl.serial==event.target){
                    this.disconnectKkt();
                }
            });
        },
        async connectKKT(manual=false){
            //console.log('connectKKT', manual, this.kkt)
            if (!this.kkt){
                this.kkt = new Kkt();
            }
            if (this.kkt){
                await this.kkt.connect(manual);
                this.isKkmConnected = this.kkt.isConnected()
            }
        },
        async disconnectKkt(){
            if (this.kkt){
                await this.kkt.disconnect()
                this.isKkmConnected = this.kkt.isConnected()
                this.kkt = null;
            } else
                this.isKkmConnected = false
        }
    }
}

export default KktMixin