import Scales from './Scales/Scales.js'
import MidlGuliver from './Scales/MidlGuliver.js'
import ShtrikhSlim from './Scales/ShtrikhSlim.js'

const ScalesMixin = {
    data: ()=>( {
        scales: null,
        isScalesConnected: false,
        isWeightSedated: false,
        inputWeight: null,
        intervalWeight: null,
    }),
    mounted(){
        setTimeout(() => {
            this.scalesCreating();
        }, 1000);
    },
    async beforeDestroy(){
        console.log('beforeDestroy')
        await this.disconnectScales()
        console.log('beforeDestroy 2')
    },
    methods: {
        async scalesCreating(){
            console.log('scalesCreating')
            await this.connectScales(false)
            navigator.serial.addEventListener("connect", async (event) => {
                console.log("event connect, ", event)
                if (!this.scales || !this.scales.isConnected())
                    await this.connectScales()
            });
            navigator.serial.addEventListener("disconnect", (event) => {
                console.log("event disconnect, ", event)
                if (this.scales && this.scales.scalesImpl && this.scales.scalesImpl.port && this.scales.scalesImpl.port==event.target){
                    this.disconnectScales();
                }
            });
        },
        checkScalesConnected(){
            this.isScalesConnected = this.scales!==null && this.scales.isConnected()
        },
        async manualConnectScales(){
            await this.connectScales(true)
        },
        async connectScales(manual=false){
            console.log('connectScales', manual)
            if (!this.scales){
                this.scales = new Scales();
            }
            if (this.scales){
                await this.scales.connect(manual);
                this.checkScalesConnected()
                if (this.scales && this.scales.scalesImpl && this.scales.scalesImpl){
                    if (this.scales.scalesImpl instanceof MidlGuliver)
                        this.scales.scalesImpl.startGettingValues((x)=>{
                            this.inputWeight = x
                        }).catch((e)=> {
                            if (e.message && e.message=='The device has been lost.'){
                                this.scales.scalesImpl=null;
                                this.checkScalesConnected()
                            }
                        })
                    else if (this.scales.scalesImpl instanceof ShtrikhSlim){
                        this.intervalWeight = setInterval(async () => {
                            const { weight, sedated } = await this.scales.getChannelState().catch((e)=>{
                                console.log('catch x', e)
                                throw e
                            })
                            this.inputWeight = weight / 1000
                            this.isWeightSedated = sedated
                        }, 100);
                    }
                }
            }
        },
        async disconnectScales(){
            if (this.intervalWeight) {
                clearInterval(this.intervalWeight)
            }
            this.isWeightSedated = false
            if (this.scales){
                await this.scales.disconnect().catch(e=>{
                    if (e.message && e.message=='The device has been lost.')
                        this.scales.scalesImpl=null;
                })
                this.checkScalesConnected()
                this.scales = null;
            }
        }
    }
}

export default ScalesMixin