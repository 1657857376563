import Vue from 'vue'
import { format } from 'date-fns'

Vue.filter('justdate', function (value) {
    if (!value) return '';
    return format(new Date(value), 'dd.MM.yyyy');
})

Vue.filter('date-ddmmyy', function (value) {
    if (!value) return '';
    return format(new Date(value), 'dd.MM.yy');
})

Vue.filter('datetime', function (value, mask) {
    if (!value) return '';
    return format(new Date(value), mask?mask:'dd.MM.yy HH:mm:ss');
})

Vue.filter('datetimeHhMm', function (value, mask) {
    if (!value) return '';
    return format(new Date(value), mask?mask:'dd.MM.yy HH:mm');
})

Vue.filter('currency-null2', function (value) {
    if (value===null)
        return '';
    return parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
})

Vue.filter('currency-null', function (value) {
    if (value===null)
        return '';
    return parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + ' ₽';
})

Vue.filter('number-null03', function (value) {
    if (value===null || isNaN(value))
        return '';
    return parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3
      });
})

Vue.filter('currency-no-cents', function (value) {
    if (!value)
        return '';
    return Math.round(value) + ' ₽';
})


  