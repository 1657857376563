<template>
    <div style="margin-bottom: 1rem;" v-if="(errors && errors.length)|| warning">
        <div v-if="errors" style="position: fixed; left: 10%; right: 10%; z-index: 100; top: 52px;">
            <div v-for="(e, idx) in errors" :key="idx"  style="z-index: 100"
                class="notification is-danger my-error animate__animated animate__fadeInDown">
                <button class="delete" @click="$emit('closeError', idx)"></button>
                <div>{{e}}</div>
                <!--div v-if="e.networkError">
                    {{e.networkError.message}}
                </div-->
            </div>
        </div>
        <div v-if="warning" class="container">
            <div class="notification is-warning my-warning animate__animated animate__fadeInDown">
                <button class="delete" @click="$emit('closeWarning')"></button>
                {{warning}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            errors: Array,
            warning: String,
        }
        
    }
</script>