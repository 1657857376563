export const ENQ = 0x05;
export const STX = 0x02;
export const ETX = 0x03;
export const ACK = 0x06;
export const NAK = 0x15;

export default class ScalesImpl{
    constructor(port, writer, reader){
        this.port = port;
        this.writer = writer;
        this.reader = reader;
        this.queue = Array();
    }

    xorControlSum(accum, data, start=0){
        let j = accum;
        if (data.constructor === Uint8Array || data.constructor === Array){
            let idx=0
            data.forEach(e => {
                if (idx++>=start)
                    j = j ^ e;
            });
        } else
            j = j ^ data;
        return j;
    }   

    async read(){
        const { value, done } = await this.reader.read();
        if (value){
            await value.forEach(e =>  {
                this.queue.push(e);
            });
        }
    }

    async write(code){
        await this.writer.write(new Uint8Array([code]));
    }

    async writeData(data){
        await this.writer.write(data);
    }

    async getByte() {
        if (!this.queue.length)
            await this.read();
        return this.queue.shift();
    }
}
