import {Command, UnicodeToWin1251} from './Command.js'

class CommandShtrikh extends Command {
    constructor(){
        super();
        if (this.getCode()>0xff)
            this.push(this.getCode()>>8)
        this.push(this.getCode() & 0xff)
    }

    getData(){
        let ret = new Uint8Array(this.pushIndex + 1);
        ret[0] = this.pushIndex;
        for (let i=0;i<=this.pushIndex;i++)
            ret[i+1] = this.data[i];
        return ret;
    }

    addAdminPassword(){
        this.push(0x1e);
        this.push(0x00);
        this.push(0x00);
        this.push(0x00);
    }

    parseResponseStart(data, parseError=true){
        //console.log('----------------------------------', this.getCode().toString(16)+'h')
        let x=(this.getCode()>0xff) ? (data.shift()<<8 | data.shift()) : data.shift()
        //console.log('Command code: ' + (x).toString(16) + 'h');
        if (parseError){
            this.errorCode = data.shift()
            //console.log('Error code: ' + this.errorCode.toString(16) + 'h');
            if (this.errorCode){
                throw new Error('Command code:' + this.getCode().toString(16) + "h, error code:"+ this.getErrorCode().toString(16)+'h')
            }
        }
    }

    parseResponseFinish(data){
        //if (data.length)
        //    console.log("Other:", data);
    }

    parseResponse(data){
        this.parseResponseStart(data)
        this.parseResponseFinish(data)
        return {}
    }
}

class GetRegime extends CommandShtrikh{
    getCode(){return 0x12}
    parseResponse(data){
        this.parseResponseStart(data)
        const d = data.shift()
        console.log("Текущий режим: ", d);
        this.parseResponseFinish(data)
        return {currentRegime: d}
    }
}

class GetChannelsCount extends CommandShtrikh{
    getCode(){return 0xe5}

    parseResponse(data){
        this.parseResponseStart(data)
        const d = data.shift()
        console.log("Количество каналов: ", d);
        this.parseResponseFinish(data)
        return {channelsCount: d}
    }
}

class GetCurrentChannel extends CommandShtrikh{
    getCode(){return 0xea}

    parseResponse(data){
        this.parseResponseStart(data)
        const d = data.shift()
        console.log("Номер канала: ", d);
        this.parseResponseFinish(data)
        return {currentChannel: d}
    }
}

class GetChannelParams extends CommandShtrikh{
    getCode(){return 0xe8}
    constructor(channel){
        super()
        this.push(channel)
    }

    parseResponse(data){
        this.parseResponseStart(data)
        ///const d = data.shift()
        //console.log("Номер канала: ", d);
        this.parseResponseFinish(data)
        return {}
    }
}

class GetChannelState extends CommandShtrikh{
    getCode(){return 0x3a}
    constructor(){
        super()
        this.addAdminPassword()
    }

    parseResponse(data){
        this.parseResponseStart(data)
        const flag1 = data.shift() | data.shift()<<8
        //console.log("флаги: ", flag1.toString(2));
        //console.log(flag1&0b01?"Вес зафиксирван":"Вес не зафиксирван");
        //if (flag1&0b010)
        //    console.log("Признак автонуля");
        //console.log(flag1&0b100?"Канал включен":"Канал выключен");
        if (flag1&0b1000)
            console.log("Признак тары");
        //console.log(flag1&0b10000?"Вес успокоен":"Вес не успокоен");
        if (flag1&0b100000)
            console.log("Ошибка автонуля при включении");
        if (flag1&0b1000000)
            console.log("Перегрузка по весу");
        if (flag1&0b10000000)
            console.log("Ошибка при получении изменения");
        if (flag1&0b100000000)
            console.log("Весы недогружены");
        if (flag1&0b1000000000)
            console.log("Нет ответа от АЦП");

        const weight = data.shift() | data.shift()<<8 | data.shift()<<16 | data.shift()<<24
        //console.log("вес: ", weight/1000);
        this.parseResponseFinish(data)
        return {weight: weight, sedated: (flag1&0b10000)>0}
    }

}



export {GetRegime, GetChannelsCount, CommandShtrikh, GetCurrentChannel, GetChannelParams, GetChannelState}