<template>
    <input ref="inputRef" @focus="onfocus" @blur="onblur" type="text"/> 
</template>

<script>
import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    value: null,
    options: Object,
  },
  setup(props) {
    const { inputRef , setOptions, setValue } = useCurrencyInput(props.options) // eslint-disable-line no-unused-vars
    watch(
        () => props.value,
        (value) => {setValue(value)}
    )

    function onfocus() {
      if (props.value === 0)
        setValue(null)
      inputRef.value.select();
    }

    function onblur() { 
      if (props.value === null)
        setValue(props.options.nullOnClean?null:0)
    }

    /*watch(
        () => props.options,
        (options) => {setOptions(options)}
    )*/
    return { inputRef, onfocus, onblur }
  }
}
</script>