import Vue from 'vue'
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import VueApollo, { ApolloProvider } from 'vue-apollo';


const httpLink = new HttpLink({
    //uri: (process.env.NODE_ENV=='development'?'http://localhost:8011':'') + '/graphql'
    uri: '/graphql'
});


const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}
    
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: defaultOptions,
});


Vue.use(VueApollo);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

export default apolloProvider
