import {ENQ, STX, ACK, NAK} from './ScalesImpl.js'
import ScalesImpl from './ScalesImpl.js'

//import {CommandShtrikh, Beep, OpenCheck, CloseCheck, Income, CutPaper, PullPaper,
//    XReport, OpenShift, PrintLine, CloseShift, GetPosStatus, GetShortPosStatus, CommonDumping,
//    GetShiftParams, NullingCheck, CancelCheck, ContinuePrint, GetMoneyRegister, } from './CommandShtrikh.js'
import {GetRegime, GetChannelsCount, GetCurrentChannel, GetChannelParams, GetChannelState} from './CommandShtrikhSlim'

export default class ShtrikhSlim extends ScalesImpl{

    constructor(port, writer, reader){
        super(port, writer, reader);
    }

    async proccess(command){
        let ret
        /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
        while (true){
            await this.write(ENQ);
            const acknak = await this.getByte();
            if (acknak == ACK) {
                ret = await this.waitAnswer(command)
                if (ret)
                    break;
            } else if (acknak == NAK){
                ret = await this.sendСommand(command)
                //console.log(ret)
                if (ret){
                    break;
                }
            }
        }
        return ret
    }

    /*async printMultiLine(text){
        for (let currentLine of text.match(/.{1,32}/g)){
            await this.proccess(new PrintLine(currentLine))
        }

    }*/

    async waitAnswer(command){
        if (await this.getByte() != STX)
            return null;
        let len = await this.getByte();
        const data = Array();
        data.push(len);
        while (len--){
            data.push(await this.getByte());
        }
        if ((await this.getByte()) == this.xorControlSum(0, data)){
            await this.write(ACK);
            data.shift();
            return command.parseResponse(data);
        } else {
            await this.write(NAK);
        }
        return null;
    }

    async sendСommand(command){
        let i = 0
        while (i < 10) {
            await this.write(STX);
            let cmdData = command.getData()
            await this.writeData(cmdData);
            await this.write(this.xorControlSum(0, cmdData));
            if ((await this.getByte()) == ACK){
                return await this.waitAnswer(command)
            }
            i++;
        }
        return null;
    }

    async getRegime(){
        return await this.proccess(new GetRegime())
    }

    async getChannelsCount(){
        return await this.proccess(new GetChannelsCount())
    }

    async getCurrentChannel(){
        return await this.proccess(new GetCurrentChannel())
    }

    async getChannelParams(){
        return await this.proccess(new GetChannelParams())
    }

    async getChannelState(){
        return await this.proccess(new GetChannelState())
    }
}
