<template>
    <div class="modal modal-fx-fadeInScale" :class="{'is-active': isActive}" @keydown.esc="$emit('close')">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card" style="background: #fff;">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isActive: Boolean,
        },
    }
</script>

