import ScalesImpl from './ScalesImpl.js'

export default class MidlGuliver extends ScalesImpl{

    constructor(port, writer, reader){
        super(port, writer, reader);
        console.log("constructor", reader)
        reader.closed.then(() => {
              console.log("reader closed");
          }).catch((e)=>{
              console.log("reader catch error", e);
          });
    }

    async startGettingValues(callback){
        if (this.alreadyGettingValue)
            return
        this.alreadyGettingValue = true
        const regex = /wn(-?\d+\.\d+)kg/;
        let str=''

        try {
            while (this.port.readable) {
                if (!this.port.readable.locked){
                    this.reader = this.port.readable.getReader()
                }
                const { value, done } = await this.reader.read();
                if (value){
                    for (let b of value)  {
                        if (b!=0x0d){
                            if (b==0x0a){
                                let m = regex.exec(str)
                                if (m !== null) {
                                    //console.log(parseFloat(m[1]))
                                    callback(parseFloat(m[1]))
                                    // return parseFloat(m[1])
                                }
                                str='';
                            } else {
                                str += String.fromCharCode(b)
                            }
                        }
                    }
                }
                if (done) {
                    this.reader.releaseLock();
                    break;
                }
            }
        } catch (error) {
            console.log(error)
            this.alreadyGettingValue=false
            throw error
        } finally {
            this.alreadyGettingValue=false
        }
        this.alreadyGettingValue=false

    }
}
