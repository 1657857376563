const userGql = `user {
    id login username fam name isAnonymous isStaff email agentId 
    groups{name}
    pos{id sklad{id name} utmUrl utmInn utmKpp utmAddress utmName kkmPrintCheque utmSendCheque}
    selfAgent hashToken
    agent{id name ownStore}
    checksumSklad checksumHost checksumReason checksumAnswer
    userGroupsTsCnt
    localback
}`

export default userGql;