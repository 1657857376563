<template>
    <div class="modal modal-fx-fadeInScale" :class="{'is-active': isActive}" @keydown.esc="$emit('close')">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card" style="background: #fff;">
                <title-with-check :type="type" :title="title" @close="$emit('close')">
                    <slot></slot>
                </title-with-check>
        </div>
    </div>
</template>
<script>
    import TitleWithCheck from './TitleWithCheck.vue'
    export default {
        props: {
            isActive: Boolean,
            type: String,
            title: String,
        },
        components: {
            TitleWithCheck,
        }
    }
</script>
