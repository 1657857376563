import {connectSerial} from '../SerialPort'
import MidlGuliver from './MidlGuliver';
import ShtrikhSlim from './ShtrikhSlim'

export default class Scales{
    constructor(){
        this.scalesImpl = null;
    }
    isConnected(){ 
        return this.scalesImpl != null;
    }
    async connect(manual=false){
        const filters = [
            { usbVendorId: 0x1fc9, usbProductId: 0x80a3, }, // Штрих-Слим
            { usbVendorId: 0x1a86, usbProductId: 0x7523, }, // CH341 usb-ttl converter для Мидл-Гуливер-07
            { usbVendorId: 0x2341, usbProductId: 0x8036, } , // aruino leonardo
            { usbVendorId: 0x10c4, usbProductId: 0xea60, } , // aruino uno keyestudio
        ];
        const port = await connectSerial(manual, filters, {baudRate: 57600}) // {baudRate: 4800})  115200
        if (port && port.readable){
            const info = port.getInfo();
            const reader = await port.readable.getReader();
            if (info.usbVendorId==0x1fc9 && info.usbProductId==0x80a3){
                const writer = await port.writable.getWriter();
                this.scalesImpl = new ShtrikhSlim(port, writer, reader)
                console.log('Штрих-Слим detected')
            }
            else if ((info.usbVendorId==0x1a86 && info.usbProductId==0x7523) || 
                     (info.usbVendorId==0x2341 && info.usbProductId==0x8036) ||
                     (info.usbVendorId==0x10c4 && info.usbProductId==0xea60)){ 
                this.scalesImpl = new MidlGuliver(port, null, reader)
                console.log('Мидл-гуливер detected')

                //let signals = await port.getSignals();
                //console.log("SIGNALS", signals)

                /*port.onconnect = function(){
                    console.log("C O N N E C T E D")
                }
                port.ondisconnect = function(){
                    console.log("D I S C O N N E C T E D")
                }*/
            }
            else{
                console.log('Unknown', port, info)
            }
        }
    }

    async disconnect(){
        await this.scalesImpl.reader.cancel()
        if (this.scalesImpl && this.scalesImpl.port && this.scalesImpl.port.readable && this.scalesImpl.port.readable.locked)
            await this.scalesImpl.reader.releaseLock();
        if (this.scalesImpl && this.scalesImpl.writer && this.scalesImpl.writer.locked)
            await this.scalesImpl.writer.releaseLock();
        await this.scalesImpl.writer.close()
        await this.scalesImpl.port.close();
        this.scalesImpl = null;
    }

    ///async proccess(command){
    //}

    async getRegime(){
        return await this.scalesImpl.getRegime();
    }

    async getChannelsCount(){
        return await this.scalesImpl.getChannelsCount();
    }

    async getCurrentChannel(){
        return await this.scalesImpl.getCurrentChannel().catch(e=>{
            console.log("catch exception getCurrentChannel")
        });
    }

    async getChannelParams(){
        return await this.scalesImpl.getChannelParams();
    }

    async getChannelState(){
        if (this.scalesImpl){
            const cs = await this.scalesImpl.getChannelState().catch(e=>{
                console.log("catch exception getChannelState")
                return {weight: null, sedated: false};
            });
            return cs
        }
        return {weight: null, sedated: false};
    }

    getScalesName(){
        if (this.scalesImpl)
            if (this.scalesImpl instanceof ShtrikhSlim)
                return 'Штрих-Слим'
            else if (this.scalesImpl instanceof MidlGuliver)
                return 'Мидл-Гуливер'
            else
                return 'неизвестный'
    }
}
