import gql from "graphql-tag"
import userGql from "./common.js"

const qrRegex = /^kr-\d{11}$/;

export const scannerMixin = {
    data() {
        return {
            scannedBarcode: null,
            serialScanner: null,
            qrTokenLoading: false,
        }
    },
    created: function() {
        document.addEventListener('kirsaExt',this.kirsaExt);
    },
    destroyed: function() {
        document.removeEventListener('kirsaExt', this.kirsaExt);
    },
    watch:{
        scannedBarcode(val) {
            if (!val)
                return
            console.log("scannerMixin", val)
            let m;
            if ((m = qrRegex.exec(val)) !== null) {
                let cryptoX = require('crypto')
                let shasum = cryptoX.createHash('sha1')
                m.forEach((match, groupIndex) => {
                    shasum.update(match)
                    if (this.user.hashToken && shasum.digest('hex')==this.user.hashToken) {
                        this.selfAgent = true;
                        this.scannedBarcode=null;
                    } else {
                        this.qrTokenLoading=true;
                        this.$apollo.mutate({
                            mutation: gql(`mutation($code: String!) {
                                qrToken(code: $code) {` + userGql + `}
                            }`),
                            variables: {
                                "code": match
                            },
                        }).then(data => { // eslint-disable-line no-unused-vars
                            this.user=data.data.qrToken.user;
                            this.scannedBarcode=null;
                            this.qrTokenLoading=false;
                        }).catch(error => {
                            this.error(JSON.stringify(error.message));
                            this.qrTokenLoading=false;
                        });
                    }
                });
            }
        }
    },
    methods: {
        connectScanner(manual) {
            document.dispatchEvent(new CustomEvent('toKirsaExt'));
        },
        kirsaExt(x){
            console.log("kirsaExt: ", x.detail);
            this.scannedBarcode = x.detail.barcode;
            if (x.detail && x.detail.msg == 'scanner connected') {
                this.serialScanner = true;
            }
            else if (x.detail && x.detail.msg == 'scanner disconnected') {
                this.serialScanner = null;
            }
        },
    }
};