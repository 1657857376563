<template>
    <article class="message is-success">
        <div class="message-header" v-if="title">
            <p>{{ title }}</p>
            <button class="delete" aria-label="delete" @click="$emit('close')"></button>
        </div>
        <div class="message-body">
            <div class="title-with-check">
                <img v-if="type=='attention'" src="@/assets/attention.png" alt="" style="width: 66px;">
                <div v-if="type=='empty'"></div>
                <svg v-if="type==null"
                    :class="{'checkmark': true}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <div class="text">
                    <slot></slot>
                </div>
            </div>
        </div>
    </article>

</template>

<script>
    export default {
        props: {
            type: String,
            title: String,
        }
    }
</script>

<style lang="scss" scoped>
    .title-with-check{
        display: flex;
        align-items: center;
        .text {
            padding-left: 20px;
            .title-text {
                font-size: 20px !important;
                margin-bottom: 1rem;
            }
            .sub-title{
                font-size: 1rem !important;
            }
        }
    }
</style>